body {
  margin: 0;
  height: 100%;
  font-style: normal;
  position: relative;
  color: var(--black);
  background: var(--white);
  font-weight: var(--font-normal);
  font-size: var(--default-font-size);
  font-family: var(--default-font-family);
  line-height: var(--default-line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .text-success {
    color: var(--success-color);
  }

  .text-danger {
    color: var(--failed-color);
  }
}
