// root variables
:root {
  // colors
  --white: #ffffff;
  --black: #000000;
  --orange: #eb5757;

  --black-4: #414141;

  // fonts
  --default-font-family: "Poppins", sans-serif;
  --default-font-size: 12px;
  --default-line-height: 16px;
  --font-normal: 400;
  --font-bold: 700;
}
