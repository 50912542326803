@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400&display=swap");

.apl-roi-calculator-app {
  // colors
  --roi-white: #ffffff;
  --roi-black: #000000;
  --roi-orange: #eb5757;

  --roi-black-4: #414141;

  // fonts
  --roi-font-family: "Poppins", sans-serif;
  --roi-font-size: 12px;
  --roi-line-height: 16px;
  --roi-font-normal: 400;
  --roi-font-bold: 700;

  width: 724px;
  font-style: normal;
  position: relative;
  color: var(--roi-black);
  background: var(--roi-white);
  font-weight: var(--roi-font-normal);
  font-size: var(--roi-font-size);
  font-family: var(--roi-font-family);
  line-height: var(--default-line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  &__questions-form {
    padding: 45px;
    padding-bottom: 25px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 13px 0;
      background-image: linear-gradient(to right, #aaaaaa 33%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 5px 1px;
      background-repeat: repeat-x;

      &:last-child {
        background-image: none;
      }

      &__question {
        width: calc(100% - 150px);
        font-weight: var(--roi-font-bold);
      }

      &__answer {
        width: 130px;

        input {
          max-width: 110px;
          height: 24px;
          border: 1px solid var(--roi-black);
          border-radius: 15px;
          padding: 0 10px;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: center;
      padding-top: 30px;

      &__submit-btn {
        width: 225px;
        height: 46px;
        background: var(--roi-black);
        border-radius: 15px;
        color: var(--roi-white);
        font-weight: var(--roi-font-bold);
        font-size: 16px;
        border: none;
        cursor: pointer;
      }
    }
  }

  &__result {
    padding: 48px 52px;

    &__title {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 25px;
      text-align: center;
    }

    &__summary {
      display: flex;
      justify-content: center;

      div {
        width: 208px;
        margin: 0 10px;
        font-weight: var(--roi-font-bold);
        font-size: 40px;
        line-height: 50px;
        color: var(--roi-orange);
        text-align: center;

        span {
          display: block;
          color: var(--roi-black-4);
          font-style: italic;
          font-weight: var(--roi-font-normal);
          font-size: 12px;
          line-height: 18px;
          text-align: center;
        }
      }
    }

    &__details {
      padding: 13px;
      border-radius: 10px;
      margin-top: 20px;
      position: relative;

      table {
        width: 100%;
        margin-bottom: 25px;

        th,
        td {
          padding: 14px;

          &.apl-roi-calculator-app__result__details__td-minutes {
            width: 100px;
          }

          &.apl-roi-calculator-app__result__details__td-cost {
            width: 135px;
          }
        }

        thead {
          th {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: var(--roi-orange);
          }
        }

        tbody {
          td {
            line-height: 18px;
            vertical-align: top;

            strong {
              display: block;
            }

            div {
              background: var(--roi-white);
              border-radius: 5px;
              height: 35px;
              font-weight: var(--roi-font-bold);
              font-size: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      button {
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        background: var(--roi-white);
        border: 1px solid var(--roi-black);
        border-radius: 15px;
        width: 136px;
        height: 26px;
        cursor: pointer;
        font-size: 11px;
        line-height: 16px;
      }

      &__hide {
        background: #ececec;
        border: 1px dashed var(--roi-black);
      }
    }

    &__bottom {
      font-size: 11px;
      line-height: 16px;
      margin-top: 50px;
      text-align: center;

      div {
        width: 294px;
        margin: 0 auto;
      }

      span {
        display: inline-block;
        margin-top: 10px;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__loading {
    height: 277px;
    padding: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    &__text {
      margin-top: 20px;
      font-size: 18px;
    }

    .loading-spinner {
      width: 50px;
      height: 50px;
      border: 10px solid #f3f3f3; /* Light grey */
      border-top: 10px solid #383636; /* Black */
      border-radius: 50%;
      animation: spinner 1.5s linear infinite;
    }
  }

  @media (max-width: 550px) {
    width: 100%;

    &__questions-form {
      padding: 15px 25px;

      &__item {
        flex-direction: column;

        &__question {
          width: 100%;
        }

        &__answer {
          width: 100%;
          margin-top: 5px;

          input {
            width: 100%;
            max-width: 100%;
            text-align: center;
          }
        }
      }

      &__actions {
        padding-top: 20px;
      }
    }

    &__result {
      padding: 25px 5px;

      &__details {
        padding: 5px;
        border-radius: 0;
        position: relative;

        table {
          th,
          td {
            padding: 4px;

            &.apl-roi-calculator-app__result__details__td-minutes {
              width: 90px;
            }

            &.apl-roi-calculator-app__result__details__td-cost {
              width: 90px;
            }
          }

          tbody {
            td {
              font-size: 11px;

              div {
                font-size: 13px;
              }
            }
          }
        }

        button {
          bottom: -13px;
        }
      }
    }
  }
}
